import { useContext } from 'react';
import { Navigate } from 'react-router-dom';

import { UserContext } from "./components/shared/context"

const AuthRequired = ({ component }) => {
  const user = useContext(UserContext)

  // if no user, redirect to home
  if (!user) return <Navigate replace to="/" />
  // if no permissions, redirect to profile
  if (!user.permissions?.length) return <Navigate replace to="/settings/profile" />
  // otherwise return the component
  return component
}

export default AuthRequired;
