import { Button } from 'antd';
import * as XLSX from 'xlsx';
import { get } from 'lodash';
import dayjs from 'dayjs';

import { ExcelIcon } from './icons';
import { useFormatMessage } from './hooks'

const generateExcelDocument = (dataSource, columns, f) => {
  if (!dataSource?.length) return;

  // for every data in dataSource create object with column titles as keys and values from data
  const arr = dataSource.map(data => columns.reduce((obj, col) => {
    // check if title has FormattedMessage id
    const key = col.title.props?.id ? f(col.title.props.id) : col.title
    return ({
      ...obj,
      [key]: col.render ? col.render(get(data, col.dataIndex), data) : get(data, col.dataIndex)
    })
  }, {}))

  const ws = XLSX.utils.json_to_sheet(arr);
  ws["!cols"] = [];
  const numberOfElements = Object.keys(arr[0]).length;
  for (let i = 0; i < numberOfElements; i++) {
    ws["!cols"].push({ wch: 18 })
  }
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Sendingar");
  XLSX.writeFile(wb, 'sendingar_pr_sendandi_' + dayjs().format('DD.MM.YYYY-HH.mm') + '.xlsx');
};

const ExportToExcel = ({ dataSource, columns }) => {
  const f = useFormatMessage()

  return (
    <Button
      type="link"
      icon={<ExcelIcon />}
      style={{ paddingLeft: 0 }}
      onClick={() => generateExcelDocument(dataSource, columns.filter(c => c.dataIndex), f)}
    >
      {f('AllShipments.ExportExcel')}
    </Button>
  )
}

export default ExportToExcel