import React, { useState } from 'react';
import dayjs from 'dayjs';
import { DatePicker, Form } from 'antd';
import { FormattedMessage } from 'react-intl';

import { CalendarIcon } from './icons'

const { Item } = Form;

const DateRange = ({
  startDate = null,
  endDate = dayjs(),
  format = 'DD. MM. YYYY',
  allowClear = false,
  onDateChange,
} = {}) => {

  const [startValue, setStartValue] = useState(startDate);
  const [endValue, setEndValue] = useState(endDate);

  const disabledStartDate = startValue => {
    if (!startValue || !endValue) {
      return false;
    }
    return startValue.valueOf() > endValue.valueOf();
  };

  const disabledEndDate = endValue => {
    if (!endValue || !startValue) {
      return false;
    }
    return endValue.valueOf() <= startValue.valueOf();
  };

  const onStartChange = value => {
    const startValue = value ? value.startOf('day') : null
    setStartValue(startValue)
    onDateChange({ startValue, endValue })
  };

  const onEndChange = value => {
    const endValue = value ? value.endOf('day') : null
    setEndValue(endValue)
    onDateChange({ startValue, endValue })
  };

  return (
    <Item label={<FormattedMessage id="RangePicker.TimePeriod" />} >
      <div className="daterange-wrapper">
        <DatePicker
          disabledDate={disabledStartDate}
          format={format}
          value={startValue}
          placeholder="Start"
          onChange={onStartChange}
          suffixIcon={<CalendarIcon />}
          allowClear={allowClear}
        />
        <DatePicker
          disabledDate={disabledEndDate}
          format={format}
          value={endValue}
          placeholder="End"
          onChange={onEndChange}
          suffixIcon={<CalendarIcon />}
          allowClear={allowClear}
        />
      </div>
    </Item>
  );
}

export default DateRange;