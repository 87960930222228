import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Button } from 'antd';

const Back = () => {
  const navigate = useNavigate()
  return (
    <Button
      type="link"
      onClick={() => navigate(-1)}
      className="link-style"
    >
      <FormattedMessage id="Button.Back" />
    </Button>
  )
}

export default Back;