import React from 'react';
import { Row, Col, Table, Typography, Spin } from 'antd';
import { FormattedMessage } from 'react-intl'

import { useFormatMessage } from '../shared/hooks'
import { sizeTranslations } from '../shared/delivery/BoxSize'

const { Title } = Typography

const columns = [
    {
        title: () => <FormattedMessage id="CreateDelivery.CompartmentSizeDescription" />,
        key: 'sizeDescription',
        dataIndex: 'sizeDescription',
    },
    {
        title: () => <FormattedMessage id="CreateDelivery.CompartmentSize" />,
        key: 'sizeText',
        dataIndex: 'sizeText',
    },
    {
        title: () => <FormattedMessage id="CreateDelivery.FreeCell" />,
        key: 'numAvailable',
        dataIndex: 'numAvailable',
    },
    {
        title: () => <FormattedMessage id="CreateDelivery.Total" />,
        key: 'numTotal',
        dataIndex: 'numTotal',
    },
];

const BoxInfo = ({ lockers, siteName }) => {
    const f = useFormatMessage()

    return (
        <Col span={22}>
            <Row>
                <Title level={2}>{siteName}</Title>
            </Row>
            <Row>
                {lockers
                    ?
                    <Table
                        columns={columns}
                        dataSource={lockers.map(locker => ({ ...locker, sizeDescription: f(sizeTranslations(locker.size)) }))}
                        pagination={false}
                        showHeader={true}
                        rowKey={(rec) => rec.siteName + rec.size}
                        scroll={{ x: 'max-content' }}
                    />
                    :
                    <Spin></Spin>
                }
            </Row>
        </Col>
    );
}

export default BoxInfo;
