import dayjs from 'dayjs';
import { get, toLower, includes } from 'lodash';
import { PhoneNumberUtil } from 'google-libphonenumber';

import { PERMISSION } from '../../Constants'

dayjs.extend(require('dayjs/plugin/isSameOrBefore'))
dayjs.extend(require('dayjs/plugin/isSameOrAfter'))

const regionCodePhone = "IS";

/* UTILS */

export function dateComparison(a, b) {
  const dateA = dayjs(a || new Date());
  const dateB = dayjs(b || new Date());

  if (dateA.isSame(dateB)) return 0;
  if (dateB.isBefore(dateA)) return -1;
  if (dateA.isBefore(dateB)) return 1;
}

export const stringComparison = (a, b) =>
  (a || '').localeCompare(b || '', undefined, { sensitivity: 'accent' });

export function boolComparison(a, b) {
  if (Boolean(a) === Boolean(b)) return 0;
  if (!a) return -1;
  if (!b) return 1;
}

export const addKey = (obj, i) => ({ ...obj, key: i })

export const objectIncludes = (obj, searchableProps, searchString = '') => searchableProps.some(
  key => includes(toLower(get(obj, key, '')), toLower(searchString))
)

export const checkPermission = (permissions, permission) =>
  permissions.includes(PERMISSION.accessAll) || permissions.includes(permission);

export const getSanitizedPhone = (phone) => {
  if (!phone) return null;

  const phoneUtil = PhoneNumberUtil.getInstance();
  const sanitized = phone.replace(/[^a-zA-Z0-9 ]/g, "");
  const numberChk = phoneUtil.parseAndKeepRawInput(sanitized, regionCodePhone);
  return numberChk.getNationalNumber();
};

// remove garbage and add hyphen
export const kennitalaNormalize = (value) => {
  const digitsOnly = value.replace(/[^0-9]/g, "")
  if (digitsOnly.length > 6) {
    return digitsOnly.slice(0, 6) + "-" + digitsOnly.slice(6)
  }
  return digitsOnly
}

export const findCompanyIdForBranchId = (branches, branchId) => {
  const branch = branches.find(({ id }) => (id === branchId));
  if (branch) return branch.companyId;
  return 0;
}

export function filterData({ senderId, showOnlyDelivered, startDate, endDate }, lines) {
  let filteredData = lines;
  if (senderId) {
    filteredData = filteredData.filter(
      (line) => line.sender && line.sender.id === senderId
    );
  }
  if (showOnlyDelivered) {
    filteredData = filteredData.filter((line) => line.delivered);
  }
  if (startDate) {
    filteredData = filteredData.filter((line) =>
      dayjs(line.createDate).isSameOrAfter(startDate)
    );
  }
  if (endDate) {
    filteredData = filteredData.filter((line) =>
      dayjs(line.createDate).isSameOrBefore(endDate)
    );
  }
  return filteredData;
}

export function groupBy(list = [], keyGetter) {
  const map = new Map();
  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
}

/* FORM VALIDATORS */

/* invalidates non-Icelandic phone numbers  */
const isValidPhone = (value) => {
  const phoneUtil = PhoneNumberUtil.getInstance();
  const number = phoneUtil.parseAndKeepRawInput(value, regionCodePhone);
  return phoneUtil.isValidNumberForRegion(number, regionCodePhone);
}

/* allow empty phone number, use required if needed */
export const phoneValidator = (rule, value) => !value || isValidPhone(value)
  ? Promise.resolve()
  : Promise.reject()

/* handle required logic only, field validators validates format */
export const emailOrPhoneRequired = (form, f) =>
  form.getFieldValue('phone') || form.getFieldValue('email')
    ? Promise.resolve()
    : Promise.reject(new Error(f('CreateDelivery.EmailOrPhoneRequired')))

function isValidDate(year, month, day) {
  // we know that the values are 0-99
  if (month > 12) return false;
  if (month === 0) return false;
  if (day === 0) return false;
  if (day > 31) return false;
  const months30 = [4, 6, 9, 11] // months with 30 days
  // check february
  if (month === 2 && day === 29) {
    const leap = ((year % 4 === 0) && (year % 100 !== 0)) || (year % 400 === 0)
    return leap
  }
  if (month === 2) return day <= 28
  // we've already checked 1-31
  // let's check if 31 is allowed or not
  const invalid = day === 31 && months30.includes(month)
  return !invalid
}

const ktError = error => ({
  kennitala: null,
  valid: false,
  type: "Error",
  error: error,
})

/**
 * allows only digits
 * @returns ktError OR kennitala object
 */
function parseKennitala(kennitala) {
  const mod11arr = [3, 2, 7, 6, 5, 4, 3, 2, 0, 0];

  if (typeof (kennitala) !== "string") return ktError("type must be string")
  const len = kennitala.length
  if (len < 10) return ktError("too short")
  if (len > 10) return ktError("too long")
  const numbers = new Array(10)
  let modSum = 0
  for (let i = 0; i < len; i++) {
    const ch = kennitala.charCodeAt(i) // no garbage collection
    if (ch >= 48 && ch < 58) {
      const num = ch - 48;
      modSum += mod11arr[i] * num;
      numbers[i] = num;
    } else return ktError("digits only")
  }

  const checkDigit = (11 - (modSum % 11)) % 11

  if (checkDigit === 10) return ktError("bad checksum")
  if (numbers[8] !== checkDigit) return ktError("bad checksum")
  if (numbers[0] >= 8) return {
    kennitala: numbers.join(""),
    valid: true,
    type: "System",
  }
  let c = numbers[9] * 100 + 2000
  if (c > 2800) c -= 1000
  let d = numbers[0] * 10 + numbers[1]
  const m = numbers[2] * 10 + numbers[3]
  const y = c + numbers[4] * 10 + numbers[5]
  const isCompany = d > 31
  if (isCompany) d -= 40
  if (!isValidDate(y, m, d)) return ktError("bad date")
  return {
    kennitala: numbers.join(""),
    valid: true,
    type: isCompany ? "Company" : "Person",
  }
}

const kennitalaCheck = (value) => {
  if (!value) return ktError("empty");
  if (typeof (value) !== "string") return ktError("type must be string")
  // clean up the string. Replace all non-numbers with nothing
  const ktStr = value.replace(/\D/g, "")
  return parseKennitala(ktStr)
}

export const kennitalaValidator = (_, value, formatter, allowToShort = false) => {
  const { error, type, kennitala } = kennitalaCheck(value)
  if (error === "empty") return Promise.resolve() // use required if needed

  if (error === "too short")
    return allowToShort
      ? Promise.resolve()
      : Promise.reject(formatter("Validation.NationalIDLength"))

  if (error === "too long")
    return Promise.reject(formatter("Validation.NationalIDLength"))
  if (type === "Error")
    return Promise.reject(formatter("Validation.NationalIDFormat"))
  if (type !== "Person")
    return Promise.reject(formatter("Validation.NationalIDPersonOnly"))
  if (kennitala[9] !== "9" && kennitala[9] !== "0")
    return Promise.reject(formatter("Validation.NationalIDAge"))

  return Promise.resolve();
}

export const kennitalaValidatorOnChange = (_, value, formatter) =>
  kennitalaValidator(_, value, formatter, true)
