import { Table } from 'antd'
import { useNavigate } from "react-router-dom";
import { useFormatMessage } from '../shared/hooks'

const DeliveryTable = ({ columns, dataSource, loading, pageSize, pagination = true }) => {
  const navigate = useNavigate()
  const f = useFormatMessage()

  return (
    <Table
      className='clickable' // table-ellipsis
      columns={columns}
      dataSource={dataSource}
      loading={loading}
      pagination={pagination && {
        showTotal: (total, range) => `${range[0]}-${range[1]} ${f('Pagination.Of')} ${total}`,
        pageSize: pageSize,
        showLessItems: true,
        showSizeChanger: false,

      }}
      scroll={{ x: 'max-content' }}
      rowKey={item => `${item.businessKey}${item.createDate}`}
      sticky
      onRow={record => ({
        onClick: () => navigate(`/about-delivery/${record.businessKey}`)
      })}
    />
  )
}

export default DeliveryTable
