import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom'
import { FormattedMessage } from 'react-intl';
import { Descriptions, Button, Layout, Col, Form, Row, Typography, Spin, message } from 'antd';
import { get, isEqual } from 'lodash';
import dayjs from 'dayjs';

import { API } from '../../AxiosWrapper';
import ShippingHistory from '../AboutDelivery/ShippingHistory';
// import BottomButtons from './BottomButtons';
// import PrintButton from './PrintButton';
import Barcode from 'react-barcode';
import { useHandleApiError, useFormatMessage, useInterval } from '../shared/hooks'
import ErrorNotFound from '../ErrorNotFound';
import Email from '../shared/delivery/Email'
import Phone from '../shared/delivery/Phone'
import RentalCar from '../shared/delivery/RentalCar'
import InputField from '../shared/delivery/InputField'
import Sender from '../shared/delivery/Sender';
import { DataContext } from "../shared/context"
import { SHOW_PRINT_BUTTON } from '../../Constants';

const { Title } = Typography

const ViewDelivery = () => {
  const { senders, branches } = useContext(DataContext)
  const { businessKey } = useParams()

  const [loading, setLoading] = useState(false)
  const [deliveryData, setDeliveryData] = useState(null)

  const getFormData = (businessKey, branches, oldData) => {
    if (!businessKey || !branches) return
    if (!oldData) setLoading(true) // show loader first time only

    API.GET(`Delivery/${businessKey}`)
      .then(res => {
        if (isEqual(oldData, res.data)) return

        setDeliveryData(res.data)
      })
      // .catch(error => {
      //   handleApiError(error)
      //   if (error.response?.status === 404)
      //     setNotFound(true)
      // })
      .finally(() => setLoading(false))
  }
  useEffect(() => {
    getFormData(businessKey, branches)
  }, [businessKey, branches]) // eslint-disable-line react-hooks/exhaustive-deps

  if (deliveryData == null) return
  const recipient = deliveryData.recipient

  return (
    <Layout.Content>
      {/* <Title level={2}><FormattedMessage id={title} /></Title> */}
      <Title level={2}>Delivery</Title>
      <Row>
        <Col xs={24} xl={12}>
          <Descriptions layout="vertical" title="delivery" column={1}>
            <Descriptions.Item label="BusinessKey">{deliveryData.businessKey}</Descriptions.Item>
            <Descriptions.Item label="Created">{deliveryData.createDate}</Descriptions.Item>
            <Descriptions.Item label="Email">{recipient.email}</Descriptions.Item>
            <Descriptions.Item label="Mobile">{recipient.phone}</Descriptions.Item>
          </Descriptions>
          <Descriptions layout="vertical" title="recipient" column={1}>
            <Descriptions.Item label="Name">{recipient.name}</Descriptions.Item>
            <Descriptions.Item label="Kennitala">{recipient.identificationNumber}</Descriptions.Item>
            <Descriptions.Item label="Email">{recipient.email}</Descriptions.Item>
            <Descriptions.Item label="Mobile">{recipient.phone}</Descriptions.Item>
          </Descriptions>
        </Col>
        <Col xs={24} xl={10}>
          <ShippingHistory
            data={deliveryData}
            delivered={deliveryData.delivered}
          />
        </Col>
      </Row>
    </Layout.Content >
  )
}

export default ViewDelivery;
