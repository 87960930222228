import React from 'react';
import Barcode from 'react-barcode';
import QRCode from 'qrcode.react';
import { Typography } from 'antd';
import { FormattedMessage } from 'react-intl';

const { Title } = Typography

const BarcodeRow = ({ barCodeVal, qrCodeVal, out }) => {
  const title = out ? "CreateDelivery.CodeForBoxOut" : "CreateDelivery.CodeForBoxIn"
  return (
    <div>
      <Title level={3}><FormattedMessage id={title} /></Title>
      <Barcode
        height={80}
        format="CODE128"
        value={barCodeVal}
      />
      <QRCode
        value={qrCodeVal}
        size={128}
        bgColor={'#ffffff'}
        fgColor={'#000000'}
        level={'L'}
        includeMargin={true}
        renderAs={'svg'}
      />
    </div>
  )
}

export default BarcodeRow;
