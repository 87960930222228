import React, { useState, useEffect, useContext } from 'react';
import { NavLink } from "react-router-dom";
import { Layout, Table, Form, Input, Typography } from 'antd';
import { FormattedMessage } from 'react-intl';

import { API } from '../../AxiosWrapper';
import { stringComparison, checkPermission, objectIncludes } from '../shared/utils';
import { useHandleApiError, useFormatMessage } from '../shared/hooks'
import { PERMISSION } from '../../Constants';
import { SearchIcon } from '../shared/icons'
import { UserContext } from "../shared/context"

const { Item } = Form;
const { Title } = Typography

const AccessGroups = () => {
  const f = useFormatMessage()
  const handleApiError = useHandleApiError();
  const { permissions } = useContext(UserContext)

  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [groupNames, setGroupNames] = useState([]);
  const [searchString, setSearchString] = useState('');

  const sorter = (g1, g2) => g1.localeCompare(g2)

  const getGroupsWithUsers = () => {
    setLoading(true)
    API.GET('admin/group/withUsers')
      .then(({ data }) => {
        const userDict = new Map()
        const groupsWithUsers = new Set()

        data.forEach(group => group.users.forEach(user => {
          const userGroups = userDict.has(user.email) ? [...userDict.get(user.email), group.name] : [group.name]
          userDict.set(user.email, userGroups)
          groupsWithUsers.add(group.name)
        }))

        const usersWithGroups = Array.from(userDict, ([email, value]) => ({ email, username: email, groups: value.sort(sorter) }))

        setGroupNames([...groupsWithUsers].sort(sorter))
        setUsers(usersWithGroups)
      })
      .catch(handleApiError)
      .finally(() => setLoading(false))
  }

  useEffect(getGroupsWithUsers, [permissions]) // eslint-disable-line react-hooks/exhaustive-deps

  const columns = [
    {
      dataIndex: 'name',
      title: <FormattedMessage id="AccessGroups.Name" />,
      render: (text, item) => item.firstName ? `${item.firstName} ${item.lastName}` : item.username,
      sorter: (a, b) => stringComparison(a.username, b.username),
    },
    {
      dataIndex: 'email',
      title: <FormattedMessage id="AccessGroups.Email" />,
      sorter: (a, b) => stringComparison(a.email, b.email),
    },
    {
      dataIndex: 'groups',
      title: <FormattedMessage id="Admin.Groups" />,
      render: (text, item) => item.groups.map(g => <div key={g}>{g}</div>),
      sorter: (a, b) => b.groups.length - a.groups.length,
      filters: groupNames.map(g => ({ text: g, value: g })),
      onFilter: (value, record) => record.groups?.includes(value),
    },
    {
      key: 'actions',
      title: <FormattedMessage id="Table.Actions" />,
      render: (text, item) => (
        <NavLink to={'/admin/create-user?id=' + item.email} className="btn-link" style={{ marginRight: 16 }}>
          <FormattedMessage id="Admin.Edit" />
        </NavLink>
      ),
    },
  ];

  const searchableProps = ['firstName', 'lastName', 'username', 'email', 'groups']

  const handleSearch = (e) => {
    setSearchString(e.target.value)
  }

  const dataSource = users.filter(obj => objectIncludes(obj, searchableProps, searchString));

  return (
    <Layout.Content>
      <Title level={2}><FormattedMessage id="Sidebar.Groups" /></Title>

      <Form layout="vertical">
        <Item label={<span><FormattedMessage id="Input.Search" /></span>}>
          <Input
            className="standard-input"
            suffix={<SearchIcon />}
            onChange={handleSearch}
            placeholder={f('Input.SearchList')}
          />
        </Item>
      </Form>

      {checkPermission(permissions, PERMISSION.adminGroup)
        ?
        <NavLink to="/admin/create-user" style={{ marginBottom: 16 }}>
          + <FormattedMessage id="CreateUser.AddUser" />
        </NavLink>
        :
        ''}

      <Table
        columns={columns}
        dataSource={dataSource}
        loading={loading}
        rowKey={'email'}
        pagination={{
          showTotal: (total, range) => `${range[0]}-${range[1]} ${f('Pagination.Of')} ${total}`,
          showLessItems: true,
          showSizeChanger: false,
        }}
        scroll={{ x: 'max-content' }}
        sticky
      />

    </Layout.Content>
  );
};

export default AccessGroups;
