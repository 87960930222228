import React from 'react';
import { Card, Typography, Row, Col } from 'antd';
import Handlebars from "handlebars";
import DOMPurify from 'dompurify';

const { Title, Text, Paragraph } = Typography

const dummyData = [
  ['Pöntunarlýsing', '{!description!}'],
  ['Pöntunarnúmer', '{!sender_order_id!}'],
  ['Sendingarnúmer', '{!business_key!}'],
  ['Strikarmerki', '{!url!}'],
  ['flefle', 'Origo SNjalalbox attribute'],
]

const stripTags = body => body?.replace(/<\/?[^>]+(>|$)/g, "")

const FrontEndPreview = ({ header, body = '', values = {} }) => {
  if (!header && !stripTags(body)) return null

  body = body ?? '' // eslint-disable-line no-param-reassign
  const regex = /\{\{(\w+)\}\}/g; // to detect {{variable}}

  // replace brackets for missing variables, in order to show them
  const replaced = body.replace(regex, (match, variableName) =>
    values[variableName] ? `{{${variableName}}}` : `{!${variableName}!}`
  )

  const template = Handlebars.compile(replaced)
  const result = template(values)

  return (
    <>
      <Card style={{ marginTop: 28 }}>
        <Title level={2} style={{ marginBottom: 28 }}>{header}</Title>
        <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(result) }} />
      </Card>
      <div style={{ background: '#EFEFEF', padding: 24 }}>
        {dummyData.map((arr, i) =>
          <Row style={{ marginBottom: 8 }} key={i}>
            <Col span={12}><Text strong>{arr[0]}</Text></Col>
            <Col span={12}>{arr[1]}</Col>
          </Row>
        )}
      </div>
      <Card>
        <Title level={3}>Hvernig á að sækja pöntunina?</Title>

        <Title level={3}>Skref 1:</Title>
        <Text>Skannaðu strikamerkið með skannanum á miðju snjallboxi.</Text><br />
        <Text>Ef þú getur ekki skannað kóðann, skrifaðu inn talnakóða fyrir snjallboxið og smelltu á #.</Text><br />
        <Text strong>Talnakóði fyrir snjallboxið er: {`${'{!box_code_readable!}'}`} #</Text>

        <Title level={3}>Skref 2:</Title>
        <Text>Snjallhólfið þitt mun opnast.</Text>

        <Title level={3}>Skref 3:</Title>
        <Text>Taktu vöruna þína og lokaðu hurðinni.</Text>
        <br /><br />
        <Paragraph>Takk fyrir viðskiptin!</Paragraph>

        <Row style={{ marginBottom: 8 }}>
          <Col span={12}>
            <img width="180" src="https://box.umsja.is/bc/img/origo-logo.png" alt='logo' />
          </Col>
          <Col span={12}>
            <Text>telephone</Text><br />
            <Text strong>{`${'{!email!}'}`} #</Text>
          </Col>
        </Row>
      </Card>
    </>
  )
}

export default FrontEndPreview
