import React, { useState, useContext, useEffect } from 'react';
import { Layout, Form, Input, Button, message, Select, Collapse, Typography, theme } from 'antd';
import { FormattedMessage } from 'react-intl'

import { API } from "../AxiosWrapper";
import { AUTH_SERVER_LINK } from '../Constants';
import { useHandleApiError, useFormatMessage } from './shared/hooks'
import { UserContext, DataContext } from "./shared/context"

const { Option } = Select;
const { Item } = Form;
const { Title } = Typography

const Profile = () => {
  const handleApiError = useHandleApiError();
  const f = useFormatMessage();
  const [msg, contextHolder] = message.useMessage();
  const {
    token: { colorBgContainer }
  } = theme.useToken()
  const { permissions, firstName, defaultSenderId, defaultBranchId } = useContext(UserContext)
  const { senders, branches, loadAllData, handleBranchChange } = useContext(DataContext)

  const [posting, setPosting] = useState(false);

  const handleSubmit = (data) => {
    setPosting(true);
    API.POST('Users/defaults', data)
      .then(loadAllData)
      .catch(handleApiError)
      .finally(() => setPosting(false));
  }

  useEffect(() => {
    if (permissions.length <= 0) {
      msg.error(f('Profile.No.Permissions'));
    }
  }, [permissions, f, msg])

  const items = [
    {
      key: 1,
      label: f('Profile.Permissions'),
      children: permissions?.map(p => <div key={p}>{p}</div>)
    }
  ]

  return (
    <Layout.Content>
      {contextHolder}
      <Title level={2}><FormattedMessage id="Header.Profile" /></Title>
      <Form
        layout="vertical"
        initialValues={{
          name: firstName,
          defaultBranchId: defaultBranchId,
          defaultSenderId: defaultSenderId,
        }}
        className="form-style"
        onFinish={handleSubmit}
      >
        <Item
          name="name"
          label={<FormattedMessage id="Profile.Name" />}
          rules={[{ required: true }]}
        >
          <Input />
        </Item>

        <Item
          name="defaultBranchId"
          label={<FormattedMessage id="Profile.DefaultBranch" />}
          rules={[{ required: true }]}
        >
          {branches.length > 0
            ? (
              <Select onChange={handleBranchChange}>
                {branches.length > 1 &&
                  <Option key={0} value={0}>
                    <FormattedMessage id="Header.AllBranches" />
                  </Option>
                }
                {branches.map((branch) => (
                  <Option key={branch.id} value={branch.id}>
                    {branch.name}
                  </Option>
                ))}
              </Select>
            )
            : <Select disabled placeholder={<FormattedMessage id="Branch.Unavailable" />} />}
        </Item>

        <Item
          name="defaultSenderId"
          label={<FormattedMessage id="Profile.DefaultSender" />}
          rules={[{ required: true }]}
        >
          {senders ?
            <Select
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {senders?.map(sender => (
                <Option key={sender.id} value={sender.id}>
                  {sender.name}
                </Option>
              ))}
            </Select>
            :
            <Select disabled placeholder={<FormattedMessage id="Sender.Unavailable" />} />
          }
        </Item>

        <Item>
          <Button type="primary" shape="round" htmlType="submit" loading={posting}>
            <FormattedMessage id="Profile.Submit" />
          </Button>
        </Item>

        <Collapse items={items} style={{ background: colorBgContainer, border: 'none' }} />

        {AUTH_SERVER_LINK && (
          <p>
            <a style={{ float: "right" }} href={AUTH_SERVER_LINK} target="_blank" rel="noopener noreferrer">
              <FormattedMessage id="Profile.AuthSettings" />
            </a>
          </p>
        )}
      </Form>
    </Layout.Content>
  );
}

export default Profile;
