import { createContext } from 'react';

export const UserContext = createContext({
  permissions: [],
  firstName: null,
  defaultSenderId: null,
  defaultBranchId: null,
});

export const DataContext = createContext({
  companyId: 0,
  branches: [],
  senders: [],
  loadAllData: () => { },
  handleBranchChange: () => { },
  fields: new Map(),
  allowedColumns: new Map(),
})
