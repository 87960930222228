import React, { useState, useEffect, useContext } from 'react';
import { Form, Row, Col, Layout, Input, Typography } from 'antd';
import { FormattedMessage } from 'react-intl';
import dayjs from 'dayjs';

import { API } from '../../AxiosWrapper';
import { filterData, objectIncludes } from '../shared/utils';
import Sender from '../shared/Sender';
import { useColumns, searchableProps } from '../shared/useColumns';
import DateRange from '../shared/DateRange';
import DeliveryTableWrapper from '../shared/DeliveryTableWrapper'
import { SearchIcon } from '../shared/icons'
import { useHandleApiError, useFormatMessage } from '../shared/hooks'
import { UserContext } from "../shared/context"

const { Item } = Form;
const { Title } = Typography

const NotDelivered = () => {
  const f = useFormatMessage()
  const handleApiError = useHandleApiError()
  const columns = useColumns()
  const { defaultSenderId, defaultBranchId: branchId } = useContext(UserContext)

  const [deliveries, setDeliveries] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(dayjs());
  const [senderId, setSenderId] = useState(defaultSenderId);
  const [loading, setLoading] = useState(false);
  const [searchString, setSearchString] = useState('');

  const getDeliveries = () => {
    setLoading(true);
    let url = 'Delivery?Delivered=false';
    if (branchId) url += `&branchId=${branchId}`;

    API.GET(url)
      .then(response => setDeliveries(response.data.reverse()))
      .catch(handleApiError)
      .finally(() => setLoading(false))
  }

  useEffect(getDeliveries, [branchId]) // eslint-disable-line react-hooks/exhaustive-deps

  const onDateChange = ({ startValue, endValue }) => {
    setStartDate(startValue);
    setEndDate(endValue);
  };

  const handleSearch = (e) => {
    setSearchString(e.target.value)
  }

  const filteredDeliveries = filterData({ senderId, showOnlyDelivered: false, startDate, endDate }, deliveries)
    .filter(obj => objectIncludes(obj, searchableProps, searchString));

  return (
    <Layout.Content>
      <Title level={2}><FormattedMessage id="Sidebar.NotDelivered" /></Title>
      <Form layout="vertical">
        <Row gutter={24}>
          <Col xs={24} lg={8}>
            <Item label={<span><FormattedMessage id="Input.Search" /></span>}>
              <Input
                className="standard-input"
                suffix={<SearchIcon />}
                onChange={handleSearch}
                placeholder={f('Input.SearchList')}
              />
            </Item>
          </Col>
          <Col xs={24} lg={8}>
            <DateRange
              onDateChange={onDateChange}
              startDate={startDate}
              endDate={endDate}
              allowClear={true}
            />
          </Col>
          <Col xs={24} lg={8} className="justify-end-responsive">
            <Sender senderId={senderId} setSenderId={setSenderId} />
          </Col>
        </Row>
      </Form>

      <DeliveryTableWrapper
        dataSource={filteredDeliveries}
        columns={columns}
        loading={loading}
      />

    </Layout.Content>
  );
}

export default NotDelivered;
