import dayjs from 'dayjs';
const duration = require('dayjs/plugin/duration')
dayjs.extend(duration)

export const defaultNotifyDuration = dayjs.duration(30, 'minutes');
export const defaultWarningDuration = dayjs.duration(2, 'hours');

// FIXME work in progress
export const actionableStates = new Map([
  [
    'decide-delivery',
    [dayjs.duration(1, 'minutes'), dayjs.duration(2, 'minutes')]
  ],
  [
    'send-staff-reminder',
    [dayjs.duration(4, 'hours'), dayjs.duration(2, 'days')]
  ],
  [
    'decide-delivery-method',
    [dayjs.duration(10, 'minutes'), dayjs.duration(30, 'minutes')]
  ],
  [
    'decide-delivery-method-pre-lockdown',
    [dayjs.duration(10, 'minutes'), dayjs.duration(30, 'minutes')]
  ],
  [
    'package-moved-from-box-v2',
    [dayjs.duration(3, 'minutes'), dayjs.duration(20, 'minutes')]
  ],
  [
    'order-placed-in-box',
    [defaultNotifyDuration, defaultWarningDuration]
  ],
  [
    'collection-collected',
    [dayjs.duration(4, 'minutes'), dayjs.duration(10, 'minutes')]
  ],
  [
    'staff-collection-reminder',
    [dayjs.duration(1, 'minutes'), dayjs.duration(2, 'minutes')]
  ],
  [
    'collection-in-bad-locker-notification',
    [dayjs.duration(1, 'seconds'), dayjs.duration(2, 'seconds')]
  ],
  [
    'verify-collection',
    [dayjs.duration(4, 'minutes'), dayjs.duration(10, 'minutes')]
  ]
]);