import { Form, Input } from 'antd';
import { useContext } from 'react';

import { DataContext } from '../context';
import { kennitalaValidator, kennitalaValidatorOnChange, kennitalaNormalize } from '../utils';
import { useFormatMessage } from '../hooks'

const { Item } = Form

const maxLength = 100 // we need some reasonable limit

const fieldConfigs = {
  createDate: {
    default: true,
  },
  createdByUserName: {
    default: true,
  },
  businessKey: {
    default: true,
  },
  kennitala: {
    normalize: kennitalaNormalize, // TODO: normalize is garbage as cursor position gets reset
    rules: [
      {
        validateTrigger: ['onChange'],
        validator: kennitalaValidatorOnChange,
      },
      {
        validateTrigger: ['onBlur', 'onSubmit'],
        validator: kennitalaValidator,
      },
    ]
  },
  recipientName: {
  },
  street: {
  },
  senderOrderId: {
  },
  description: {
  },
  currentState: {
    default: true,
  },
  location: {
    default: true,
  },
  // TODO should be called lockerStatus
  apexStatus: {
    default: true,
  },
  branchName: {
    default: true,
  },
}

/**
 * Reusable Input Field, with validation, that could be readOnly or disabled.
 * @prop {name} string (required)
 * @prop {disabled} bool (optional)
 * @prop {readOnly} bool (optional)
 */
const InputField = ({ name, disabled = false, readOnly = false }) => {
  const f = useFormatMessage()
  const { fields } = useContext(DataContext)

  const fieldUsed = name => fields.get(name)?.used ?? false
  const isRequired = name => fields.get(name)?.required ?? false

  const fieldConfig = fieldConfigs[name]

  if (!(fieldConfig.default || fieldUsed(name))) return null

  const baseRule = {
    type: "string",
    whitespace: true,
    required: isRequired(name)
  }

  const extraRules = (fieldConfig.rules ?? []).map(rule => ({
    ...rule,
    // we get the message from the validator and need to pass in the formatter
    validator: (ruleParam, value) => rule.validator(ruleParam, value, f)
  }))

  const rules = [baseRule].concat(extraRules)

  return (
    <Item
      name={name}
      label={f(`Delivery.${name}.label`)}
      valuePropName="value"
      messageVariables={{ msgLabel: f(`Delivery.${name}.validationName`) }}
      rules={rules}
      normalize={fieldConfig.normalize}
      validateTrigger={["onBlur", "onSubmit", "onChange"]}
      hasFeedback
    >
      <Input readOnly={readOnly} disabled={disabled} maxLength={maxLength}></Input>
    </Item>
  )
}

export default InputField
