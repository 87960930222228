export const labelStyle = {
  marginBottom: 8,
  display: 'block',
  fontSize: 16,
  fontWeight: 'bold'
};

export const cardStyle = {
  display: 'flex',
  justifyContent: 'start',
  alignItems: 'center',
  height: 94,
};

export const cardInfoStyle = {
  textAlign: 'center',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '100%',
}

export const cardTextStyle = {
  display: 'block',
}
