import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import { FormattedMessage } from 'react-intl';
import { Form, Button, Layout, Select, Input, Spin, Typography } from 'antd';
import { map, get } from 'lodash';

import { API } from '../../AxiosWrapper';
import BoxSize from '../shared/delivery/BoxSize';
import { getSanitizedPhone, phoneValidator } from '../shared/utils';
import { useHandleApiError, useFormatMessage } from '../shared/hooks'
import { UserContext, DataContext } from "../shared/context"

const { Item } = Form;
const { Option } = Select;
const { Title } = Typography

const RegisterReturn = () => {
    const [form] = Form.useForm()
    const f = useFormatMessage()
    const navigate = useNavigate()
    const handleApiError = useHandleApiError();
    const { defaultSenderId, defaultBranchId: branchId } = useContext(UserContext)
    const { companyId } = useContext(DataContext)

    const [senders, setSenders] = useState([]);
    const [senderId, setSenderId] = useState(defaultSenderId);
    const [loading, setLoading] = useState(false);
    const [lockers, setLockers] = useState(null);

    const getBoxSizes = (branchId) => {
        setLoading(true);
        API.GET(`Site/${branchId}/lockerTotals`)
            .then((response) => setLockers(get(response, 'data', [])))
            .catch(handleApiError)
            .finally(() => setLoading(false))
    };

    const handleSubmit = () => {
        const url = 'Delivery/collection';
        form.validateFields()
            .then((values) => {
                setLoading(true);
                const newData = {
                    deliveryBranchId: branchId,
                    companyId: companyId,
                    senderOrderId: values.senderOrderId,
                    senderId: values.senderId,
                    recipient: {
                        name: values.recipient,
                        email: values.email,
                        phone: getSanitizedPhone(values.phone),
                        street: values.street,
                    },
                    description: values.description,
                    boxSize: values.boxSize,
                    deliveryMethod: "BOX",
                };

                API.POST(url, newData)
                    .then(res => navigate(`/about-delivery/${res.data}`))
                    .catch(handleApiError)
                    .finally(() => setLoading(false))
            })
    };

    const getSender = () => {
        setLoading(true);
        API.GET('Sender/forCollection')
            .then(({ data }) => {
                if (!data) return
                setSenders(data);
                setSenderId(data[0].id);
            })
            .catch(handleApiError)
            .finally(() => setLoading(false))
    };

    useEffect(() => {
        if (branchId === 0) return;
        getSender();
        getBoxSizes(branchId);
    }, [branchId]) // eslint-disable-line react-hooks/exhaustive-deps

    if (!branchId) return "Branch is not selected.";
    if (loading || !lockers) return <Spin size="large" />;

    return (
        <Layout.Content>
            <Title level={2} style={{ marginBottom: 16 }}><FormattedMessage id="CreateDelivery.CollectionTitle" /></Title>
            <Form
                form={form}
                onFinish={handleSubmit}
                labelAlign="left"
                layout="vertical"
                initialValues={{
                    senderId,
                    boxSize: get(lockers, '[0].size'),
                }}
                className="form-style"
            >
                <Item
                    name="senderId"
                    label={<FormattedMessage id="CreateCollection.ReceivingCompany" />}
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: <FormattedMessage id="CreateDelivery.SenderRequired" />
                        }
                    ]}
                >
                    <Select
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {map(senders, (sender) => (
                            <Option key={sender.id} value={sender.id}>
                                {sender.name}
                            </Option>
                        ))}
                    </Select>
                </Item>
                <Item
                    name="recipient"
                    label={<FormattedMessage id="CreateCollection.Customer" />}
                    rules={[
                        { required: true, message: 'Please input customer name!' },
                    ]}
                    hasFeedback
                >
                    <Input />
                </Item>
                <Item
                    name="phone"
                    label={<FormattedMessage id="CreateCollection.Phone" />}
                    rules={[
                        { required: true, whitespace: true, message: f('Validation.PhoneRequired'), },
                        { validator: phoneValidator, message: f("Validation.PhoneInvalid") },
                    ]}
                    hasFeedback
                >
                    <Input />
                </Item>
                <Item
                    label={<FormattedMessage id="CreateCollection.Email" />}
                    hasFeedback
                    name="email"
                    rules={[
                        { type: 'email', message: f('Validation.EmailInvalid') },
                        { required: true, whitespace: true, message: f('Validation.EmailRequired') }
                    ]}
                >
                    <Input type="email" />
                </Item>
                <Item
                    name="street"
                    label={<FormattedMessage id="CreateCollection.Address" />}
                    hasFeedback
                >
                    <Input />
                </Item>
                <hr />
                <Item
                    name="senderOrderId"
                    label={<FormattedMessage id="DeliveryColumn.senderOrderId" />}
                    hasFeedback
                    rules={[
                        {
                            type: 'string',
                            required: true,
                            message: <FormattedMessage id="CreateDelivery.OrderNumberRequired" />
                        },
                    ]}
                >
                    <Input />
                </Item>
                <Item
                    name="description"
                    label={<FormattedMessage id="CreateDelivery.OrderDescription" />}
                    hasFeedback
                    rules={[
                        {
                            type: 'string',
                            required: true,
                            message: <FormattedMessage id="CreateDelivery.OrderDescriptionRequired" />
                        },
                    ]}
                >
                    <Input />
                </Item>
                <BoxSize lockers={lockers} />
                <Item>
                    <Button type="primary" htmlType="submit" shape="round">
                        <FormattedMessage id="CreateCollection.RegisterCollectionSubmit" />
                    </Button>
                </Item>
            </Form>
        </Layout.Content>
    );
};

export default RegisterReturn;
