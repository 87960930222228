import React, { useState, useEffect, useContext } from 'react';
import { Layout, Row, Col } from 'antd';

import { API } from '../../AxiosWrapper';
import FreeCompartments from './FreeCompartments';
import LockersCount from './LockersCount';
import DateRangeStats from './DateRangeStats';
import { useHandleApiError } from '../shared/hooks'
import { UserContext } from '../shared/context'

const { Content } = Layout

const Dashboard = () => {
  const handleApiError = useHandleApiError();
  const { defaultBranchId: branchId } = useContext(UserContext)

  const [loadingGeneralStats, setLoadingGeneralStats] = useState(false)
  const [generalStats, setGeneralStats] = useState({})

  const getGeneralStats = () => {
    let url = 'Dashboard/general'
    if (branchId) url += `?branchId=${branchId}`;

    setLoadingGeneralStats(true)
    API.GET(url)
      .then(response => setGeneralStats(response.data))
      .catch(handleApiError)
      .finally(() => setLoadingGeneralStats(false))
  }

  useEffect(getGeneralStats, [branchId]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Layout>
      <Content>
        <Row gutter={24}>
          <Col xs={24} xl={12} style={{ marginTop: 40 }}>
            <DateRangeStats branchId={branchId} />
          </Col>
          <Col xs={24} xl={12} style={{ marginTop: 6 }}>
            <LockersCount generalStats={generalStats} loading={loadingGeneralStats} />
            <FreeCompartments
              data={generalStats?.freeCompartments}
              loading={loadingGeneralStats}
            />
          </Col>
        </Row>
      </Content>
    </Layout>
  );
}

export default Dashboard;
