import React, { useEffect, useState, useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { Layout, Typography } from 'antd';

import { API } from '../../AxiosWrapper';
import { actionableStates } from './helpers';
import { useHandleApiError } from '../shared/hooks'
import DeliveryTableWrapper from '../shared/DeliveryTableWrapper'
import { UserContext } from '../shared/context'
import { useColumns } from '../shared/useColumns';

const { Content } = Layout;
const { Title } = Typography

const pollingInterval = 60000 // 1 min

const baseColumns = [
  // 'senderOrderId',
  // 'recipient.name',
  // 'recipient.identificationNumber',
  'orderType',
  'currentState',
  'deliveryBranchId',
  'modifyDate'
]

const ActionList = () => {
  const handleApiError = useHandleApiError()
  const user = useContext(UserContext)
  const { defaultBranchId: branchId } = user
  const columns = useColumns(baseColumns)

  const [deliveries, setDeliveries] = useState([])
  const [loading, setLoading] = useState()

  const getDeliveryData = (branchId) => {
    setLoading(true)
    const url = `Delivery?Delivered=false${branchId ? `&branchId=${branchId}` : ''}`
    API.GET(url).then(({ data }) => {
      const deliveries = data
        .reverse()
        .filter(d => actionableStates.has(d?.currentState?.stateId))
      setDeliveries(deliveries)
    })
      .catch(handleApiError)
      .finally(() => setLoading(false))
  };

  useEffect(() => {
    const interval = setInterval(() => getDeliveryData(branchId), pollingInterval)

    getDeliveryData(branchId);

    return () => {
      clearInterval(interval);
    }
  }, [branchId]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Layout>
      <Content>
        <Title level={2}><FormattedMessage id="Home.ActionList" /></Title>
        <DeliveryTableWrapper
          columns={columns}
          dataSource={deliveries}
          loading={loading}
          initialPageSize={7}
          showExport={false}
          showConfig={false}
        />
      </Content>
    </Layout>
  );
}

export default ActionList;
