import React, { useState, useEffect, useContext } from 'react';
import { Button, Form, Input, Space, Spin } from 'antd';
import { FormattedMessage } from 'react-intl';
import { API } from '../../AxiosWrapper';
import { useHandleApiError, useFormatMessage, useInterval } from '../shared/hooks'
import BoxSize from '../shared/delivery/BoxSize';

const { Item } = Form

const CommandButton = ({ textId, onClick }) => {
  return (
    <Item name={textId}>
      <Button
        shape="round"
        type="primary"
        onClick={onClick}
      >
        <FormattedMessage id={textId} />
      </Button>
    </Item>
  )
}

const ShelfButton = ({ textId, onClick }) => {
  const f = useFormatMessage()
  return (
    <Space.Compact>
      <Item
        name="shelfLocation"
        rules={[{
          required: true,
          message: f('CreateDelivery.ShelfRequired'),
        }]}
      >
        <Input
          placeholder={f("CreateDelivery.ShelfPlaceholder")}
          style={{ width: '180px' }}
        />
      </Item>
      <Item name={textId}>
        <Button
          shape="round"
          type="primary"
          onClick={onClick}
        >
          <FormattedMessage id={textId} />
        </Button>
      </Item>
    </Space.Compact>
  )
}

const BoxButton = ({ textId, onClick, branchId }) => {
  const [lockers, setLockers] = useState(null);
  const f = useFormatMessage()
  const handleApiError = useHandleApiError()

  const getBoxSizes = (branchId) => {
    API.GET(`Site/${branchId}/lockerTotals`)
      .then(response => setLockers(response.data))
      .catch(handleApiError)
  };

  useEffect(() => {
    // console.log("branchId", branchId)
    if (!branchId || branchId == 0) return
    getBoxSizes(branchId);
  }, [branchId]) // eslint-disable-line react-hooks/exhaustive-deps

  // if (!some(lockers, l => l.lockerType === "Apex")) return null
  // meh
  if (lockers == null) return (
    <>
      <div>
        <Spin />
      </div>
      <Item name={textId} disabled>
        <Button
          shape="round"
          type="primary"
          disabled
        >
          <FormattedMessage id={textId} />
        </Button>
      </Item>
    </>
  )
  return (
    <>
      <BoxSize lockers={lockers} />
      <Item name={textId}>
        <Button
          shape="round"
          type="primary"
          onClick={onClick}
        >
          <FormattedMessage id={textId} />
        </Button>
      </Item>
    </>
  )
}

const commandToTextId = (commandName) => {
  switch (commandName) {

    case 'cancel-box-before': return "CreateDelivery.CancelBox"
    case "delivery-method-counter": return "CreateDelivery.DeliverPackage"
    case "delivery-method-shelf": return "CreateDelivery.MoveToShelf"
    case "delivery-method-box": return "CreateDelivery.SendToBox"
    case "delivery-method-store": return "CreateDelivery.MoveToStore"
    case "delivery-method-undecided": return "CreateDelivery.DecideLater"
    case "delivery-method-abort": return "CreateDelivery.CancelDelivery"

    case "cancel-box": return "CreateDelivery.CancelBox"
    case "verify-item-in-box": return "CreateDelivery.VerifyItemInBox"
    case "lockdown": return "CreateDelivery.RemoveFromBox"
    case "deliver-shelf-old": return "CreateDelivery.MoveToShelf"
    case "cancel-collection-waiting": return "CreateCollection.CancelBox"
    case "verify-collection": return "CreateCollection.VerifyCollectionReceived"
    case "reject-collection": return "CreateCollection.RejectCollection"
    case "verify-collection-before": return "CreateCollection.VerifyReservation"
    case "cancel-collection-before": return "CreateCollection.CancelReservation"
    case "deliver-to-customer": return "AboutDelivery.DeliverPackage"
    case "cancel-shelf": return "AboutDelivery.MoveFromShelf"
    case "resend-notification": return "CreateDelivery.ResendNotification"
    default:
      console.log("command not found", commandName)
      return "CreateDelivery.CancelBox"
  }
}

const commandToButton = ({ command, textId }, handleSubmit, branchId) => {
  if (command === "delivery-method-shelf") return (
    <ShelfButton
      key={"wrapper-" + command}
      textId={textId}
      onClick={() => handleSubmit(command, ['shelfLocation'])}
    />
  )
  if (command === "delivery-method-box") return (
    <BoxButton
      key={"wrapper-" + command}
      textId={textId}
      branchId={branchId}
      onClick={() => handleSubmit(command, ['boxSize'])}
    />
  )
  return (
    <CommandButton
      key={"wrapper-" + command}
      textId={textId}
      onClick={() => handleSubmit(command)}
    />
  )
}

const BottomButtons = ({ commands, handleSubmit, branchId }) => {
  return commands.map(c =>
    commandToButton({ textId: commandToTextId(c.name), command: c.name }, handleSubmit, branchId)
  )
}

export default BottomButtons;
