import React, { useState } from 'react';
import { Form, Input, Button, Typography } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { FormattedMessage } from "react-intl";
import { useFormatMessage } from './shared/hooks'

const { Title } = Typography

const { Item } = Form

const Login = () => {
    const f = useFormatMessage()

    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);

    const getQueryVariable = (variable) => {
        const query = window.location.search.substring(1);
        const vars = query.split("&");
        for (let i = 0; i < vars.length; i++) {
            let pair = vars[i].split("=");
            if (decodeURIComponent(pair[0]) == variable) {
                return decodeURIComponent(pair[1]);
            }
        }
    };

    const handleSubmit = (values) => {
        const returnUrl = getQueryVariable("ReturnUrl");
        values.returnUrl = returnUrl;
        setLoading(true);
        fetch("/authenticate/login", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
            body: JSON.stringify(values),
        })
            .catch((error) => console.log(error))
            .then(() => window.location.assign("/"));
    };

    return (
        <Form
            form={form}
            layout="vertical"
            onFinish={handleSubmit}
        >
            <Title level={2}>Login</Title>

            <div className="form-style" style={{ marginTop: 16 }}>
                <Item
                    name="Username"
                    label={f('Profile.Email')}
                    rules={[
                        { type: 'email', message: f('Validation.EmailInvalid') },
                        { required: true, whitespace: true, message: f('Validation.ContactEmailRequired') }
                    ]}
                >
                    <Input prefix={<UserOutlined />} />
                </Item>
                <Item
                    name="password"
                    label={f('Login.Password')}
                    rules={[{ required: true, message: f('Validation.PasswordRequired') }]}
                >
                    <Input.Password prefix={<LockOutlined />} />
                </Item>
                <Item>
                    <Button type="primary" shape="round" htmlType="submit" loading={loading}>
                        <FormattedMessage id="Login.SubmitButton" defaultMessage="Sign In" />
                    </Button>
                </Item>
            </div>
        </Form>
    );
}

export default Login
