import React, { useContext } from "react"
import { Form, Checkbox } from 'antd';

import { useFormatMessage } from '../hooks'
import { DataContext } from "../context";

const { Item } = Form

const RentalCar = ({ disabled = false } = {}) => {
  const f = useFormatMessage()
  const { fields } = useContext(DataContext)

  if (!fields.get('rentalCar')?.used) return null

  return (
    <Item
      name="rentalCar"
      valuePropName="checkbox"
      messageVariables={{ msgLabel: f('Delivery.rentalCar.validationName') }}
      rules={[
        {
          required: !!fields.get('rentalCar')?.required,
        }
      ]}
      hasFeedback
    >
      <Checkbox disabled={disabled}>{f('Delivery.rentalCar.label')}</Checkbox>
    </Item>
  )
}

export default RentalCar
