import React, { useState, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Layout } from 'antd';
import { isEmpty } from 'lodash';

import AppRoutes from './AppRoutes'
import AuthRequired from '../AuthRequired';
import { API } from '../AxiosWrapper';
import SiteHeader from './Header/SiteHeader';
import Sidebar from './Sidebar/Sidebar';
import { useHandleApiError, useTheme } from './shared/hooks'
import { findCompanyIdForBranchId } from './shared/utils'
import { UserContext, DataContext } from './shared/context'

const { Content } = Layout;

const fieldMapping = new Map([
  ["recipient.kennitala", "kennitala"],
  ["rentalCar", "rentalCar"],
  ["recipient.name", "recipientName"],
  ["recipient.phone", "phone"],
  ["recipient.email", "email"],
  ["recipient.emailOrPhone", "emailOrPhone"],
  ["recipient.street", "street"],
  ["senderOrderId", "senderOrderId"],
  ["description", "description"],
])

const columnMapping = new Map([
  ["recipient.kennitala", "recipient.identificationNumber"],
  ["recipient.name", "recipient.name"],
  ["recipient.phone", "recipient.phone"],
  ["recipient.email", "recipient.email"],
  ["recipient.emailOrPhone", null],
  ["recipient.street", "recipient.street"],
  ["senderOrderId", "senderOrderId"],
  ["description", "description"],
  ["rentalCar", "rentalCar"],
])

const AppLayout = ({ setLocale, setUserTheme }) => {
  const handleApiError = useHandleApiError();
  const theme = useTheme()

  const [loaded, setLoaded] = useState(false);
  const [branches, setBranches] = useState([]);
  const [senders, setSenders] = useState([]);
  const [fieldConfiguration, setFieldConfiguration] = useState(new Map());
  const [user, setUser] = useState();

  const resolveDefaultSender = (userDefaultSenderId, senders) => {
    if (isEmpty(senders)) return userDefaultSenderId;

    let defaultId = userDefaultSenderId
    if (senders.length === 1) {
      defaultId = senders[0].id
    }
    if (!senders.find((s) => s.id === defaultId)) {
      defaultId = null;
    }
    return defaultId;
  }

  const resolveDefaultBranch = (userDefaultBranchId, branches) => {
    if (branches.length === 1) return branches[0].id

    if (branches.some(b => b.id === userDefaultBranchId)) return userDefaultBranchId

    if (localStorage.branchId) return parseInt(localStorage.getItem('branchId'))

    return 0 // all branches
  }

  const mapServerConfig = (serverConfig = {}) => {
    const serverMap = new Map(Object.entries(serverConfig))
    fieldMapping.forEach((v, key) => {
      if (!serverMap.has(key)) {
        // create default values for those values that don't come from the server
        serverMap[key] = { used: false, required: false, key: key, columnKey: key }
        if (process.env.NODE_ENV === 'development')
          console.error(`validation for ${key} did not come from server`)
      }
    })

    // log when frontend is missing the correct handler
    serverMap.forEach((v, key) => {
      if (!fieldMapping.has(key)) {
        if (process.env.NODE_ENV === 'development')
          console.error(`validation for ${key} is not known in frontend`)
      }
    })

    // filter out a map (convert to array and back)
    return new Map(
      Array.from(serverMap)
        .filter(([key]) => fieldMapping.has(key)) // filter out if fieldMapping doesn't support it
        .map(([key, validation]) => [fieldMapping.get(key), { ...validation, columnKey: columnMapping.get(key) ?? key }])
    )
  }

  const getBranches = () => API.GET('site')
    .then(response => response.data)
    .catch(error => handleApiError(error, 'branches'))

  const getSenders = () => API.GET('sender')
    .then(response => response.data)
    .catch(error => handleApiError(error, 'senders'))

  const getUser = () => API.GET('users/current')
    .then(response => response.data)
    .catch(error => handleApiError(error, 'user'))

  const getFieldConfiguration = (branch) => {
    let url = 'configuration/validations'
    if (branch) {
      url = url + '?branchId=' + branch
    }
    return API.GET(url)
      .then(response => response.data)
      .catch(error => handleApiError(error, 'configuration'))
  }

  const loadAllData = async () => {
    try {
      const branches = await getBranches() || []
      setBranches(branches)

      const senders = await getSenders() || []
      setSenders(senders)

      const user = await getUser()
      const defaultBranchId = resolveDefaultBranch(user?.defaultBranchId, branches)
      const serverConfig = await getFieldConfiguration(defaultBranchId)
      setFieldConfiguration(mapServerConfig(serverConfig))
      setUser({
        ...user,
        defaultBranchId: defaultBranchId,
        defaultSenderId: resolveDefaultSender(user?.defaultSenderId, senders),
      })
      setLoaded(true)
    } catch (error) {
      setLoaded(false);
      if (process.env.NODE_ENV === 'development')
        console.error(error)
    }
  };

  useEffect(() => {
    localStorage.removeItem('loggingOut');
    loadAllData();
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handleBranchChange = async (id) => {
    // should this be an effect?
    const serverConfig = await getFieldConfiguration(id)
    const config = mapServerConfig(serverConfig)
    setFieldConfiguration(config)
    setUser({ ...user, defaultBranchId: id })
    localStorage.setItem('branchId', id)
  };

  if (!loaded || !user || user?.defaultBranchId === undefined) return null

  const companyId = findCompanyIdForBranchId(branches, user.defaultBranchId)

  const allowedColumns = new Map(
    Array.from(fieldConfiguration)
      .map(([_, values]) => [values.columnKey, { used: values.used, required: values.required }])
  )

  return (
    <BrowserRouter>
      <UserContext.Provider value={user}>
        <Layout style={{ minHeight: '100vh' }} className={theme}>
          <AuthRequired component={<Sidebar />} />
          <DataContext.Provider
            value={{
              companyId,
              branches,
              senders,
              loadAllData,
              handleBranchChange,
              fields: fieldConfiguration,
              allowedColumns,
            }}
          >
            <Layout>
              <SiteHeader
                setLocale={setLocale}
                setUserTheme={setUserTheme}
              />
              <Content className="main-content">
                <AppRoutes />
              </Content>
            </Layout>
          </DataContext.Provider>
        </Layout>
      </UserContext.Provider>
    </BrowserRouter>
  )
}

export default AppLayout
