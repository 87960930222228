import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Typography, Spin, Form } from 'antd';
import { FormattedMessage } from 'react-intl';
import dayjs from 'dayjs';

import { API } from '../../AxiosWrapper';
import { useHandleApiError, useFormatMessage } from '../shared/hooks'
import DateRange from '../shared/DateRange';
import Histogram from './Histogram'
import { labelStyle } from './style';

const { Text } = Typography;

const deliveryType = 1
const format = "MM-DD-YYYY"

const DateRangeStats = ({ branchId }) => {
  const f = useFormatMessage()
  const handleApiError = useHandleApiError();

  const [startDate, setStartDate] = useState(dayjs().subtract(1, 'week'));
  const [endDate, setEndDate] = useState(dayjs());

  const [loading, setLoading] = useState(false);
  const [dateRangeStats, setDateRangeStats] = useState({});

  const getDateRangeStats = (startDate, endDate, deliveryType) => {
    const dateFrom = startDate.format(format)
    const dateTo = endDate.format(format)

    setLoading(true)
    API.GET(`Dashboard/period?BranchId=${branchId}&DateFrom=${dateFrom}&DateTo=${dateTo}&deliveryType=${deliveryType}`)
      .then(response => setDateRangeStats(response.data))
      .catch(handleApiError)
      .finally(() => setLoading(false))
  }

  useEffect(() => getDateRangeStats(startDate, endDate, deliveryType), [branchId]) // eslint-disable-line react-hooks/exhaustive-deps

  const onDateChange = ({ startValue, endValue }) => {
    setStartDate(startValue);
    setEndDate(endValue);
    getDateRangeStats(startValue, endValue, deliveryType)
  }

  const getPopular = () => {
    const sorted = dateRangeStats.histogram.toSorted((a, b) => b.count - a.count)
    const item = sorted[0]
    return `${item.start.slice(0, -3)} - ${item.end.slice(0, -3)}`
  }

  const getAverage = () => {
    const hours = Math.floor(dateRangeStats.average / 3600);
    const minutes = Math.floor((dateRangeStats.average % 3600) / 60);
    const hourLocale = hours === 1 ? 'Time.hour' : 'Time.hours'
    const minuteLocale = minutes === 1 ? 'Time.minute' : 'Time.minutes'

    return `${hours} ${f(hourLocale)} ${minutes} ${f(minuteLocale)}`
  }

  return (
    <>
      <Form layout="vertical">
        <Card className="home-card" style={{ padding: 6, marginBottom: 40 }}>
          <div className="home-daterange" style={{ textAlign: 'center' }}>
            <DateRange
              onDateChange={onDateChange}
              startDate={startDate}
              endDate={endDate}
              allowClear={false}
            />
            <Row style={{ textAlign: 'center' }} >
              <Col span={12}>
                <Text style={labelStyle}><FormattedMessage id="Home.DeliveryTime" /></Text>
                <Text style={{ fontSize: 22 }}>
                  {loading
                    ? <Spin />
                    : dateRangeStats?.totalDelivered
                      ? getAverage()
                      : <FormattedMessage id="Home.NoData" />
                  }
                </Text>
              </Col>
              <Col span={12}>
                <Text style={labelStyle}><FormattedMessage id="Home.PickedUpTime" /></Text>
                <Text style={{ fontSize: 22 }}>
                  {loading
                    ? <Spin />
                    : dateRangeStats?.totalDelivered
                      ? getPopular()
                      : <FormattedMessage id="Home.NoData" />
                  }
                </Text>
              </Col>
            </Row>
          </div>
        </Card>
      </Form>
      <Histogram stats={dateRangeStats.histogram} />
    </>
  );
}

export default DateRangeStats;
