import axios from "axios";
import { USE_LOGIN } from "./Constants";

const baseURL = '/frontend-api/';
const notificationUrl = '/notification-api/'

axios.interceptors.response.use(config => config, error => {
    if (error.response.status === 401 && !localStorage.getItem("loggingOut")) {
        console.log("No longer authenticated")
        if (USE_LOGIN) {
            window.location.assign("/login");
        } else {
            window.location.assign("/user/login/?" + Date.now());
        }
    }
    return Promise.reject(error);
})

/* FUNCTIONS */

const getHeaders = () => ({
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest'
})

function logout() {
    localStorage.setItem('loggingOut', 'true');
    window.location.assign("/user/logout");
}

function GET(url, baseUrl = baseURL) {
    const options = {
        method: 'GET',
        baseURL: baseUrl,
        headers: getHeaders(),
        url: url,
    };
    return axios.request(options);
}

function POST(url, data, baseUrl = baseURL) {
    const options = {
        method: 'POST',
        baseURL: baseUrl,
        data: data,
        headers: getHeaders(),
        url: url,
    };
    return axios.request(options);
}

function PUT(url, data, baseUrl = baseURL) {
    const options = {
        method: 'PUT',
        baseURL: baseUrl,
        data: data,
        headers: getHeaders(),
        url: url,
    };
    return axios.request(options);
}

function PATCH(url, data, baseUrl = baseURL) {
    const options = {
        method: 'PATCH',
        baseURL: baseUrl,
        data: data,
        headers: getHeaders(),
        url: url,
    };
    return axios.request(options);
}

function DELETE(url, data, baseUrl = baseURL) {
    const options = {
        method: 'DELETE',
        baseURL: baseUrl,
        data: data,
        headers: getHeaders(),
        url: url,
    };
    return axios.request(options);
}

/* API */

export const API = {
    GET,
    POST,
    PUT,
    PATCH,
    DELETE,
    logout
};

export const notificationAPI = {
    GET: (url) => GET(url, notificationUrl),
    POST: (url, data) => POST(url, data, notificationUrl),
    PUT: (url, data) => PUT(url, data, notificationUrl),
    DELETE: (url, data) => DELETE(url, data, notificationUrl),
}
