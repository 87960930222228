import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from "react-router-dom";
import { Row, Col, Layout, Form, Input, message, Spin, Button, Typography } from 'antd';

import { API } from '../../AxiosWrapper';
import Back from '../shared/Back';
import { phoneValidator } from '../shared/utils';
import { useHandleApiError, useFormatMessage } from '../shared/hooks'

const { Item } = Form;
const { Title } = Typography

const maxLength = 100

const CreateSender = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate()
  const f = useFormatMessage()
  const handleApiError = useHandleApiError();

  const [data, setData] = useState();
  const [posting, setPosting] = useState(false);
  const [loading, setLoading] = useState(false);

  const getSender = id => {
    setLoading(true);
    API.GET(`Sender/${id}`)
      .then(response => setData(response.data))
      .catch(handleApiError)
      .finally(() => setLoading(false))
  };

  useEffect(() => {
    const id = new URL(window.location).searchParams.get('id')
    if (id) getSender(id)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => form.resetFields(), [form, data]); // https://github.com/ant-design/ant-design/issues/22372

  const handlePatch = (values, id) => {
    const url = 'Sender/' + (id ? id : '');
    const newData = [
      {
        path: 'id',
        op: 'replace',
        value: data.id
      },
      {
        path: 'identificationNumber',
        op: 'replace',
        value: values.identificationNumber
      },
      {
        path: 'name',
        op: 'replace',
        value: values.name
      },
      {
        path: 'street',
        op: 'replace',
        value: values.street
      },
      {
        path: 'city',
        op: 'replace',
        value: values.city
      },
      {
        path: 'postalCode',
        op: 'replace',
        value: values.postalCode
      },
      {
        path: 'email',
        op: 'replace',
        value: values.email
      },
      {
        path: 'phone',
        op: 'replace',
        value: values.phone
      },
      {
        path: 'contactName',
        op: 'replace',
        value: values.contactName
      },
      {
        path: 'contactEmail',
        op: 'replace',
        value: values.contactEmail
      },
      {
        path: 'contactPhone',
        op: 'replace',
        value: values.contactPhone
      },
      {
        path: 'workflowEmail',
        op: 'replace',
        value: values.workflowEmail
      }
    ];

    API.PATCH(url, newData)
      .then(() => {
        message.success(f('CreateSender.Updated'));
        navigate('/admin/senders')
      })
      .catch(handleApiError)
      .finally(() => setPosting(false))
  }

  const handlePost = (values) => {
    const url = 'Sender';
    const data = {
      identificationNumber: values.identificationNumber,
      name: values.name,
      street: values.street,
      city: values.city,
      postalCode: values.postalCode,
      email: values.email,
      phone: values.phone,
      contactName: values.contactName,
      contactEmail: values.contactEmail,
      contactPhone: values.contactPhone,
      workflowEmail: values.workflowEmail
    };

    API.POST(url, data)
      .then(() => {
        message.success(f('CreateSender.Saved'));
        navigate('/admin/senders')
      })
      .catch(handleApiError)
      .finally(() => setPosting(false))
  }

  const handleSubmit = values => {
    setPosting(true);
    if (data)
      handlePatch(values, data.id);
    else
      handlePost(values);
  };

  if (loading) return <Spin size="large" />;

  return (
    <Layout.Content>
      <Row>
        <Col xs={24} lg={12}>
          <Title level={2} style={{ marginBottom: 16 }}>
            <FormattedMessage id={data ? 'CreateSender.EditSender' : 'CreateSender.CreateSender'} />
          </Title>
          <Back />

          <Form
            form={form}
            layout="vertical"
            className="form-style"
            onFinish={handleSubmit}
            initialValues={{
              name: data?.name,
              identificationNumber: data?.identificationNumber,
              street: data?.street,
              city: data?.city,
              postalCode: data?.postalCode,
              email: data?.email,
              phone: data?.phone,
              contactName: data?.contactName,
              contactEmail: data?.contactEmail,
              contactPhone: data?.contactPhone,
              workflowEmail: data?.workflowEmail,
            }}
          >
            <Item
              name="name"
              rules={[
                { required: true, whitespace: true, message: f('Validation.NameRequired') }
              ]}
              label={<FormattedMessage id="InfoSender.Sender" />}
              hasFeedback
            >
              <Input maxLength={maxLength} />
            </Item>
            <Item
              label={<FormattedMessage id="Senders.IdentificationNumber" />}
              name="identificationNumber"
              rules={[
                { required: true, whitespace: true, message: f('Validation.NationalIDRequired') },
                { pattern: /^\d+$/, message: f("Validation.OnlyNumbers") },
                { len: 10 }
              ]}
              hasFeedback
            >
              <Input maxLength={10} />
            </Item>
            <Item
              name="street"
              rules={[
                { required: true, whitespace: true, message: f('Validation.AddressRequired') }
              ]}
              label={<FormattedMessage id="InfoSender.Address" />}
              hasFeedback
            >
              <Input maxLength={maxLength} />
            </Item>
            <Item
              name="city"
              rules={[
                { required: true, whitespace: true, message: f('Validation.CityRequired') }
              ]}
              label={<FormattedMessage id="InfoSender.City" />}
              hasFeedback
            >
              <Input maxLength={maxLength} />
            </Item>
            <Item
              name="postalCode"
              rules={[
                { required: true, whitespace: true, message: f('Validation.PostalCodeRequired') },
                { pattern: /^\d+$/, message: f("Validation.OnlyNumbers") },
                { len: 3 }
              ]}
              label={<FormattedMessage id="InfoSender.PostalCode" />}
              hasFeedback
            >
              <Input maxLength={3} />
            </Item>
            <Item
              name="email"
              rules={[
                { type: 'email', message: f('Validation.EmailInvalid') },
                { required: true, whitespace: true, message: f('Validation.EmailRequired') }
              ]}
              label={<FormattedMessage id="InfoSender.Email" />}
              hasFeedback
            >
              <Input type="email" maxLength={maxLength} />
            </Item>
            <Item
              name="phone"
              rules={[
                { required: true, whitespace: true, message: f('Validation.PhoneRequired'), },
                { validator: phoneValidator, message: f("Validation.PhoneInvalid") },
              ]}
              label={<FormattedMessage id="InfoSender.PhoneNumber" />}
              hasFeedback
            >
              <Input type="tel" maxLength={15} />
            </Item>

            <hr />

            <Item
              name="contactName"
              rules={[
                { required: true, whitespace: true, message: f('Validation.ContactNameRequired') }
              ]}
              label={<FormattedMessage id="InfoSender.ContactName" />}
              hasFeedback
            >
              <Input maxLength={maxLength} />
            </Item>
            <Item
              name="contactEmail"
              rules={[
                { type: 'email', message: f('Validation.EmailInvalid') },
                { required: true, whitespace: true, message: f('Validation.ContactEmailRequired') }
              ]}
              label={<FormattedMessage id="InfoSender.ContactEmail" />}
              hasFeedback
            >
              <Input type="email" maxLength={maxLength} />
            </Item>
            <Item
              name="contactPhone"
              rules={[
                { required: true, whitespace: true, message: f('Validation.ContactPhoneRequired') },
                { validator: phoneValidator, message: f("Validation.PhoneInvalid") },
              ]}
              label={<FormattedMessage id="InfoSender.ContactPhone" />}
              hasFeedback
            >
              <Input type="tel" maxLength={15} />
            </Item>
            <Item
              name="workflowEmail"
              rules={[
                { type: 'email', message: f('Validation.EmailInvalid') },
              ]}
              label={<FormattedMessage id="InfoSender.WorkflowEmail" />}
              hasFeedback
            >
              <Input type="email" maxLength={maxLength} />
            </Item>
            <Item>
              <Button
                type="primary"
                htmlType="submit"
                loading={posting}
                shape="round"
              >
                <FormattedMessage id="CreateSender.Save" />
              </Button>
            </Item>
          </Form>
        </Col>
      </Row>
    </Layout.Content>
  );
}

export default CreateSender;
