import React from 'react'
import { Chart } from "react-google-charts"

import { useFormatMessage, useTheme } from '../shared/hooks'
import { stringComparison } from '../shared/utils'

const Histogram = ({ stats }) => {
  const f = useFormatMessage()
  const isDarkMode = useTheme() === 'dark'

  if (!stats || !stats?.reduce((acc, x) => acc + x.count, 0)) return null

  const getTitle = ({ start, end }) =>
    `${start.substring(0, start.length - 3)}-${end.substring(0, start.length - 3)}`

  const data = stats
    .toSorted((a, b) => stringComparison(a.start, b.start))
    .reduce((acc, el) => [
      ...acc,
      [getTitle(el), el.count]
    ], [[f('Time.hour'), f('CreateDelivery.PackagesNumber')]])

  const textStyle = isDarkMode ? { color: '#FFF' } : {}

  return (
    <Chart
      chartType="ColumnChart"
      width="100%"
      height="400px"
      data={data}
      options={{
        title: f('Home.PickedUpTime'),
        titleTextStyle: textStyle,
        colors: [isDarkMode ? '#E1E8FF' : '#283A78'],
        backgroundColor: { fill: isDarkMode ? '#2D2D2D' : '#ffffff' },
        legend: { textStyle },
        vAxis: { textStyle },
        hAxis: { textStyle },
      }}
    />
  )
}

export default Histogram