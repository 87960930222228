import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { FormattedMessage } from 'react-intl';
import {
  Form, Row, Col, Layout, Input, Button, message, Spin, Typography,
} from 'antd';

import { API } from '../../AxiosWrapper';
import { phoneValidator } from '../shared/utils';
import Back from '../shared/Back';
import { useHandleApiError, useFormatMessage } from '../shared/hooks'

const { Item } = Form;
const { Title } = Typography

const maxLength = 100

const CreateCompany = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const f = useFormatMessage();
  const handleApiError = useHandleApiError();

  const [posting, setPosting] = useState(false);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  const getCompany = id => {
    setLoading(true);
    API.GET(`Company/${id}`)
      .then(response => setData(response.data.information))
      .catch(handleApiError)
      .finally(() => setLoading(false))
  };

  useEffect(() => {
    const id = new URL(window.location).searchParams.get('id');
    if (id) getCompany(id);
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => form.resetFields(), [form, data]); // https://github.com/ant-design/ant-design/issues/22372

  const handleCreate = (values) => {
    API.POST('Company', values)
      .then(() => {
        message.success(f('CreateCompany.SuccessfullyCreated'));
        navigate('/admin/companies')
      })
      .catch(handleApiError)
      .finally(() => setPosting(false))
  }

  const handleUpdate = values => {
    const id = new URL(window.location).searchParams.get('id');
    API.POST(`Company/${id}`, values)
      .then(() => {
        message.success(f('CreateCompany.SuccessfullyUpdated'))
        navigate('/admin/companies')
      })
      .catch(handleApiError)
      .finally(() => setPosting(false))
  }

  const onFinish = (values) => {
    Object.keys(values).forEach(key => {
      if (!values[key]) delete values[key];
    });

    setPosting(true);
    if (data)
      handleUpdate(values);
    else
      handleCreate(values);
  }

  if (loading) return <Spin size="large" />;

  return (
    <Layout.Content>
      <Row gutter={32}>
        <Col xs={24} lg={{ span: 12 }}>
          <Title level={2} style={{ marginBottom: 16 }}>
            <FormattedMessage id={data ? 'CreateCompany.EditCompany' : 'Sidebar.CreateCompany'} />
          </Title>
          <Back />

          <Form
            layout="vertical"
            className="form-style"
            onFinish={onFinish}
            form={form}
            initialValues={{
              name: data?.name,
              kennitala: data?.identifier,
              street: data?.street,
              city: data?.city,
              postalCode: data?.postalCode,
              country: data?.country,
              email: data?.email,
              phone: data?.phone,
            }}
          >
            <Item
              label={<FormattedMessage id="SenderUser.Name" />}
              name="name"
              rules={[
                { required: true, whitespace: true, message: f('Validation.NameRequired') }
              ]}
              hasFeedback
            >
              <Input maxLength={maxLength} />
            </Item>
            <Item
              label={<FormattedMessage id="Senders.IdentificationNumber" />}
              name="kennitala"
              rules={[
                { required: true, whitespace: true, message: f('Validation.NationalIDRequired') },
                { pattern: /^\d+$/, message: f("Validation.OnlyNumbers") },
                { len: 10 }
              ]}
              hasFeedback
            >
              <Input maxLength={10} />
            </Item>
            <Item
              name="street"
              label={<FormattedMessage id="DeliveryColumn.recipient.street" />}
              rules={[
                { required: true, whitespace: true, message: f('Validation.AddressRequired') }
              ]}
              hasFeedback
            >
              <Input maxLength={maxLength} />
            </Item>
            <Item
              name="city"
              label={<FormattedMessage id="InfoSender.City" />}
              rules={[
                { required: true, whitespace: true, message: f('Validation.CityRequired') }
              ]}
              hasFeedback
            >
              <Input maxLength={maxLength} />
            </Item>
            <Item
              name="postalCode"
              label={<FormattedMessage id="InfoSender.PostalCode" />}
              rules={[
                { required: true, whitespace: true, message: f('Validation.PostalCodeRequired') },
                { pattern: /^\d+$/, message: f("Validation.OnlyNumbers") },
                { len: 3 }
              ]}
              hasFeedback
            >
              <Input maxLength={3} />
            </Item>
            <Item
              name="country"
              label={<FormattedMessage id="CreateCompany.Country" />}
            >
              <Input maxLength={maxLength} />
            </Item>
            <Item
              name="email"
              label={<FormattedMessage id="SenderUser.Email" />}
              rules={[{ type: 'email', message: f('Validation.EmailInvalid') }]}
              hasFeedback
            >
              <Input type="email" maxLength={maxLength} />
            </Item>
            <Item
              name="phone"
              label={<FormattedMessage id="CreateDelivery.Mobile" />}
              rules={[
                { required: true, whitespace: true, message: f('Validation.PhoneRequired'), },
                { validator: phoneValidator, message: f("Validation.PhoneInvalid") },
              ]}
              hasFeedback
            >
              <Input type="tel" maxLength={15} />
            </Item>
            <Item>
              <Button type="primary" shape="round" htmlType="submit" loading={posting}>
                <FormattedMessage id={data ? 'CreateSender.Save' : 'Sidebar.CreateCompany'} />
              </Button>
            </Item>
          </Form>
        </Col>
      </Row>
    </Layout.Content>
  );
}

export default CreateCompany;
