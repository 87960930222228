import React, { useState, useEffect, useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Form, Row, Col, Input, Select, message, Typography, Modal } from 'antd';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { map } from 'lodash';

import { notificationAPI } from '../../AxiosWrapper';
import { useHandleApiError, useFormatMessage, useTheme } from '../shared/hooks'
import BackendPreview from './BackendPreview'
// import Parameters from './Parameters'
import SMSEditor from './SMSEditor'
import FrontEndPreview from './FrontEndPreview'
import { DataContext, UserContext } from '../shared/context'

const { Item } = Form;
const { Option } = Select;
const { Title } = Typography

const templateNames = ["order-in-box", "box-reminder", "order-on-shelf", "shelf-reminder", "moved-to-shelf", "locked-down", "sender-reminder", "staff-reminder", "locker-reserved", "customer-collection-reminder", "staff-collection-in-box", "staff-collection-reminder", "staff-locker-cancelled", "customer-locker-cancelled", "verify-locker", "unreserve-locker", "bad-locker", "invite-user", "add-user", "reinvite-user"]

const orderTypes = {
  delivery: 'Delivery'
}

const savedValues = {}

const TemplateEditor = () => {
  const { senders, branches } = useContext(DataContext)
  const user = useContext(UserContext)

  const f = useFormatMessage()
  const { locale } = useIntl()
  const [form] = Form.useForm()
  const theme = useTheme()

  const [templateLoading, setTemplateLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [lastKey, setLastKey] = useState('')
  const [header, setHeader] = useState('')
  const [body, setBody] = useState('')

  const isType = key => form.getFieldValue('notificationType') === key

  const stripQuotes = (val, templateType) => val && (templateType === 'parameter')
    ? val.slice(1, -1)
    : val

  const setValue = (templateType, val = '', templateName = '') => {
    const value = stripQuotes(val, templateType)
    form.setFieldValue(templateType, value)
    if (templateName === 'title') setHeader(value)
    if (templateName === 'main-text') setBody(value)
  }

  const setSavedValue = (templateType, val = '') => {
    savedValues[templateType] = stripQuotes(val, templateType)
  }

  const getOwnerJson = (templateType) => {
    const ownerJson = {
      orderType: form.getFieldValue('orderType'),
      senderId: form.getFieldValue('senderId')?.toString(),
      branchId: form.getFieldValue('branchId')?.toString(),
      companyId: branches.find(b => b.id === form.getFieldValue('branchId'))?.companyId?.toString(),
    }
    const isMainTemplate = ["email-subject", "email", "sms"].includes(templateType)
    if (!isMainTemplate) {
      ownerJson.template = form.getFieldValue('templateName')
    }
    return JSON.stringify(ownerJson)
  }

  const getTemplate = async ({ templateType, templateName }) => {
    setTemplateLoading(true);
    const ownerJson = getOwnerJson(templateType)
    /*
    notificationAPI.GET(`Notification/all/${ownerJson}/${templateType}/${templateName}/${locale}`)
      .then(({ data }) => console.log(data))
      .catch(error => message.error('Could not load template: ' + templateType))
    */
    notificationAPI.GET(`Notification/${ownerJson}/${templateType}/${templateName}/${locale}`)
      .then(({ data }) => {
        setSavedValue(templateType, data.text)
        setValue(templateType, data.text, templateName)
      })
      .catch(() => message.error('Could not load template: ' + templateType))
      .finally(() => setTemplateLoading(false))
  }

  const getData = () => {
    if (isType('email')) {
      const promises = [
        getTemplate({ templateType: 'email-subject', templateName: form.getFieldValue('templateName') }),
        getTemplate({ templateType: 'email-partial', templateName: 'main-text' }),
        getTemplate({ templateType: 'parameter', templateName: 'title' })
      ]
      Promise.all(promises)
    }
    if (isType('sms')) {
      getTemplate({ templateType: 'sms', templateName: form.getFieldValue('templateName') })
    }
  }

  useEffect(getData, [branches, locale]); // eslint-disable-line react-hooks/exhaustive-deps

  const missingFields = () =>
    !form.getFieldValue('senderId') || !form.getFieldValue('branchId') || !form.getFieldValue('templateName')

  const putTemplate = ({ templateType, templateName, data }) => {
    setTemplateLoading(true);

    const ownerJson = getOwnerJson(templateType)
    const url = `Notification/${ownerJson}/${templateType}/${templateName}/${locale}`

    return notificationAPI.PUT(url, JSON.stringify(data));
  }

  const updateData = () => {
    getData()
    localStorage.setItem('templateEditor', JSON.stringify({
      branchId: form.getFieldValue('branchId'),
      senderId: form.getFieldValue('senderId'),
      templateName: form.getFieldValue('templateName'),
      notificationType: form.getFieldValue('notificationType'),
      orderType: form.getFieldValue('orderType'),
    }))
  }

  const isSame = key => savedValues[key]
    ? savedValues[key] === form.getFieldValue(key)
    : true

  const isSameEmail = () => isSame('email-subject') && isSame('email-partial') && isSame('parameter')

  const areValuesSame = () => {
    const changedValue = JSON.parse(localStorage.getItem('templateEditor'))[lastKey]
    if (changedValue === 'email')
      return isSameEmail()
    else if (changedValue === 'sms')
      return isSame('sms')
    else if (isType('email'))
      return isSameEmail()
    else if (isType('sms'))
      return isSame('sms')
  }

  const handleSelect = (value, key) => {
    setLastKey(key)
    if (!areValuesSame())
      setOpen(true)
    else
      updateData()
  }

  const onConfirm = () => {
    setOpen(false)
    updateData()
  }

  const onCancel = () => {
    const oldValue = JSON.parse(localStorage.getItem('templateEditor'))[lastKey]
    setValue(lastKey, oldValue)
    setOpen(false)
  }

  const onFinish = async () => {
    if (missingFields()) return

    const emailSubject = form.getFieldValue('email-subject')
    const parameter = form.getFieldValue('parameter')
    const emailPartial = form.getFieldValue('email-partial')
    const sms = form.getFieldValue('sms')

    const promises = []

    if (isType('email')) {
      if (emailSubject)
        promises.push(putTemplate({ templateType: 'email-subject', templateName: form.getFieldValue('templateName'), data: emailSubject }))
      if (parameter)
        promises.push(putTemplate({ templateType: 'parameter', templateName: 'title', data: `"${parameter}"` }))
      if (emailPartial)
        promises.push(putTemplate({ templateType: 'email-partial', templateName: 'main-text', data: emailPartial }))
    }

    if (isType('sms') && sms)
      promises.push(putTemplate({ templateType: 'sms', templateName: form.getFieldValue('templateName'), data: sms }))

    Promise.all(promises)
      .then(() => message.success(f('Generic.Success')))
      .catch(useHandleApiError)
      .finally(() => setTemplateLoading(false))
  }

  const sendEmail = () => {
    const templateType = 'email'
    const templateName = form.getFieldValue('templateName')
    const ownerJson = getOwnerJson(templateType)
    const jsonData = [{
      "service": 0, // 0 for email, 1 for mobile
      "address": user.email || user.firstName
    }]
    notificationAPI.POST(`Notification/${ownerJson}/${templateName}/${locale}/send`, jsonData)
      .then(() => message.success(f('TemplateEditor.SendEmail.Success')))
      .catch(() => message.error(f('TemplateEditor.SendEmail.Error')))
  }

  return (
    <>
      <Title level={2} style={{ marginBottom: 16 }}><FormattedMessage id="Sidebar.TemplateEditor" /></Title>

      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        initialValues={{
          notificationType: 'sms',
          /* preserves selection on reload */
          ...JSON.parse(localStorage.getItem('templateEditor'))
        }}
      >
        <Row gutter={24}>
          <Col xs={24} xl={12}>
            <Modal
              title={f('TemplateEditor.ConfirmLeave')}
              open={open}
              onOk={onConfirm}
              onCancel={onCancel}
            >
              <p>{f('TemplateEditor.ConfirmLeave.Message')}</p>
            </Modal>

            <Item name="notificationType" label={f('TemplateEditor.NotificationType')}>
              <Select onChange={value => handleSelect(value, "notificationType")}>
                <Option key="sms" value="sms">SMS</Option>
                <Option key="email" value="email">Email</Option>
              </Select>
            </Item>

            <Item
              name="branchId"
              label={f('DeliveryColumn.deliveryBranchId')}
              rules={[
                { type: 'number', required: true, whitespace: true }
              ]}
            >
              <Select onChange={value => handleSelect(value, "branchId")}>
                {map(branches, (branch => (
                  <Option key={branch.id} value={branch.id}>
                    {branch.name}
                  </Option>
                )))}
              </Select>
            </Item>

            <Item
              name="templateName"
              label={f('TemplateEditor.MessageType')}
              rules={[
                { required: true, whitespace: true }
              ]}
            >
              <Select onChange={value => handleSelect(value, "templateName")}>
                {map(templateNames, (name => (
                  <Option key={name} value={name}>{name}</Option>
                )))}
              </Select>
            </Item>
          </Col>

          <Col xs={24} xl={12}>
            <Item
              name="senderId"
              label={f('DeliveryColumn.sender.name')}
              rules={[
                { type: 'number', required: true, whitespace: true }
              ]}
            >
              <Select onChange={value => handleSelect(value, "senderId")}>
                {map(senders, (sender => (
                  <Option key={sender.id} value={sender.id}>
                    {sender.name}
                  </Option>
                )))}
              </Select>
            </Item>

            <Item name="orderType" label={f('TemplateEditor.OrderType')}>
              <Select onChange={value => handleSelect(value, "orderType")}>
                <Option key={0} value=""></Option>
                {Object.keys(orderTypes).map(key => (
                  <Option key={key} value={key}>{orderTypes[key]}</Option>
                ))}
              </Select>
            </Item>
          </Col>
        </Row>

        <hr />

        <Row justify="end" style={{ marginBottom: 16 }}>
          <Button
            style={{ marginRight: 16 }}
            onClick={sendEmail}
            disabled={isType('sms')}
          >
            {f('TemplateEditor.SendEmail')}
          </Button>

          <BackendPreview
            form={form}
            getOwnerJson={getOwnerJson}
            missingFields={missingFields}
            isType={isType}
            locale={locale}
          />
        </Row>

        <Row gutter={24}>
          <Col xs={24} xl={12}>
            {isType('email') && (
              <>
                <Item name="email-subject" label={f('TemplateEditor.Subject')}>
                  <Input disabled={templateLoading} />
                </Item>
                <Item name="parameter" label={f('TemplateEditor.Header')}>
                  <Input disabled={templateLoading} onChange={e => setHeader(e.target.value)} />
                </Item>
                <Item name="email-partial" label={f('TemplateEditor.MainText')}>
                  <ReactQuill
                    readOnly={templateLoading}
                    onChange={val => setBody(val)}
                    style={{ background: theme === 'light' ? 'white' : 'unset' }}
                  />
                </Item>
              </>
            )}

            {isType('sms') &&
              <SMSEditor loading={templateLoading} form={form} />
            }

            <Row justify="end">
              <Item>
                <Button danger style={{ marginRight: 16 }}>
                  <FormattedMessage id="CreateDelivery.Cancel" />
                </Button>

                <Button type="primary" htmlType="submit" loading={templateLoading} disabled={missingFields()}>
                  <FormattedMessage id="CreateSender.Save" />
                </Button>
              </Item>
            </Row>
          </Col>

          <Col xs={24} xl={12}>
            {isType('email') && <FrontEndPreview header={header} body={body} />}
            {/* <Parameters
              getOwnerJson={getOwnerJson}
              missingFields={missingFields}
              locale={locale}
            /> */}
          </Col>
        </Row>
      </Form>
    </>
  )
}

export default TemplateEditor
