import dayjs from 'dayjs';
import { FormattedMessage } from 'react-intl';

const relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)

const dateFormat = 'YYYY-MM-DD HH:mm';

export const columns = [
  {
    key: 'topic',
    dataIndex: 'topic',
    render: topic => <FormattedMessage id={topic} />,
  },
  {
    key: 'timeSentUTC',
    dataIndex: 'timeSentUTC',
    render: timeSentUTC => dayjs(timeSentUTC).format(dateFormat),
  },
  {
    key: 'timeSentUTC1',
    dataIndex: 'timeSentUTC',
    render: timeSentUTC => dayjs(timeSentUTC).fromNow(),
  },
]